import React, { Suspense } from 'react';

// External Components
import { Section, Box } from '@thepuzzlers/pieces';

// Local Components
import {
  ImageContainer,
  Vector,
  DividerLine,
  GridSpacer,
  LottieWrapper,
  MarkedHeading
  // WhiteNoiseSpacer as NoiseBox
} from 'components';

// Lotties
import stepOneLottie from 'lotties/step-1.json';
import stepTwoLottie from 'lotties/step-2.json';
import stepThreeLottie from 'lotties/step-3.json';

// Animation
import { animateHeadlineImage } from './animation';

// Helpers
import { convertApiHtmlText } from 'graphqlHooks/helper';

const lotties = [stepOneLottie, stepTwoLottie, stepThreeLottie];

export const Steps = ({
  data: { headline, process_steps },
  staticData: { headlineImage, steps }
}) => {
  return (
    <Section id="process-page__steps">
      {/* Image is currently  not used */}
      {/* <Spacer position={0} /> */}
      {/* <HeadlineImage data={headlineImage} /> */}
      <Headline data={convertApiHtmlText(headline.html)} />
      <Spacer position={1} />
      {steps.map((stepItem, index) => (
        <React.Fragment key={index}>
          <StepDividerLine />
          <StepItem
            key={index}
            data={stepItem}
            description={convertApiHtmlText(
              process_steps[index].description.html
            )}
            index={index}
          />
          {index === steps.length - 1 && <StepDividerLine />}
        </React.Fragment>
      ))}
    </Section>
  );
};

// not used at the moment
const HeadlineImage = ({ data: { src, alt } }) => {
  React.useEffect(() => {
    return animateHeadlineImage();
  }, []);
  return (
    // AdditionalBox is needed for animation purposes
    <Box
      className="steps__headline-image-container"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '1/25',
          '2 / span 22',
          '2 / span 22'
        ],
        aspectRatio: ['1/1.24', '1/1.24', '1.33/1', '2.13/1', '2.13/1', '2/1'],
        overflow: 'hidden',
        position: 'relative'
      }}>
      <ImageContainer
        className="steps__headline-image"
        src={src}
        alt={alt}
        sx={{
          height: '100%'
        }}
      />
      {/* was not used - even before commenting out the image */}
      {/* <NoiseBox
        className="steps__headline-image-cover"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      /> */}
    </Box>
  );
};

const Headline = ({ data }) => {
  return (
    <MarkedHeading
      as="h2"
      sx={{
        lineHeight: 1.15,
        fontSize: ['3.4rem', '3.8rem', '4.4rem', '3.2rem', '4.4rem', '4.8rem'],
        // without image
        mt: ['11.5rem', '14.5rem', '15.9rem', '10rem', '16rem', '20rem'],

        // with image
        // mt: ['11.5rem', '14.5rem', '15.9rem', '11.1rem', '23.5rem', '35.9rem'],
        textTransform: 'uppercase',
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '1/25',
          '1 / span 22',
          '1 / span 19'
        ],
        '& > br': {
          display: [null, null, null, 'none', 'none', 'none']
        }
      }}
      data={data}
    />
  );
};

// TODO: Update the content of the steps description below
const StepItem = ({ data: { number, illustration }, description, index }) => {
  return (
    <Box
      className="steps__step-item"
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25'],
        display: 'grid',
        gridTemplateColumns: [
          null,
          null,
          null,
          '1fr 25.3rem',
          '1fr 37rem',
          '1fr 41.6rem'
        ],
        py: ['6.4rem', '8rem', '8rem', '7.6rem', '9.1rem', '8.9rem'],
        px: [null, null, null, null, null, '5.2rem'],
        columnGap: [null, null, null, '6.6rem', '13.4rem', '23.1rem']
      }}>
      <StepItemIllustration
        number={number}
        illustration={illustration}
        index={index}
      />
      <StepItemDescription data={description} />
    </Box>
  );
};

// TODO: make the crane has special position due to the image weight feel is not in the middle
const StepItemIllustration = ({ number, index }) => {
  const lottieStyles = [
    {
      m: '-62% -38% -24%'
    },
    {
      m: '-62% -38% -24%'
    },
    {
      m: '-20% -38% -24%'
    }
  ];

  const lottieSpeeds = [0.5, 0.7, 1];

  return (
    <Box
      className="steps__step-item-illustration"
      sx={{
        position: 'relative',
        width: ['20.9rem', '29.8rem', '29rem', '19.5rem', '29.1rem', '27.9rem'],
        justifySelf: ['center', 'center', 'center', 'start', 'start', 'start'],
        alignSelf: 'center'
      }}>
      <Suspense fallback={<div />}>
        <LottieWrapper
          lottieSpeed={lottieSpeeds[index]}
          animationData={lotties[index]}
          sx={lottieStyles[index]}
        />
      </Suspense>
      <Vector
        src={number.src.publicURL}
        alt={number.alt}
        sx={{
          height: ['5.6rem', '7.8rem', '7.8rem', '4.9rem', '5.6rem', '8.8rem'],
          position: 'absolute',
          right: [0, 0, 0, 0, 0, '-9rem'],
          bottom: [
            '-5.6rem',
            '-7.8rem',
            '-3.6rem',
            '-2.3rem',
            '-2.5rem',
            '-1.6rem'
          ]
        }}
      />
    </Box>
  );
};

const StepItemDescription = ({ data }) => {
  return (
    <MarkedHeading
      as="p"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.5,
        fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
        mt: ['12.5rem', '14.6rem', '12.6rem', 0, 0, 0],
        justifySelf: [null, null, 'center', 'start', 'start', 'start'],
        alignSelf: 'center',
        width: [null, null, '64.8rem', '100%', '100%', '100%'],
        gridColumn: [null, null, null, 1, 1, 1],
        gridRow: [null, null, null, 1, 1, 1]
      }}
      data={data}
    />
  );
};

const StepDividerLine = () => (
  <DividerLine
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25']
    }}
  />
);

const Spacer = ({ position }) => {
  const heights = [
    ['15.2rem', '21.2rem', '16.9rem', '10.8rem', '20.8rem', '23.5rem'],
    ['11.4rem', '11.6rem', '9.8rem', '10.8rem', '7.6rem', '10.8rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
