import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const statisticBarAnimation = (
  barClassName,
  textClassName,
  percentageValue,
  prefix
) => {
  const textElement = document.querySelector(textClassName);

  const graphAnimationVertical = () => {
    ScrollTrigger.saveStyles([barClassName, textClassName]);

    if (!textElement) return;
    textElement.innerHTML = '0 %';

    const timeline = gsap.timeline({
      defaults: {
        duration: 2
      },
      scrollTrigger: {
        trigger: barClassName,
        start: 'bottom bottom'
      }
    });

    timeline.from(barClassName, {
      scaleY: 0,
      transformOrigin: 'bottom center',
      onUpdate: () => {
        const progress = (timeline.progress() * percentageValue).toFixed(0);
        textElement.innerHTML = `${prefix} ${progress} %`;
      }
    });

    return () => {
      timeline.kill();
    };
  };

  const graphAnimationHorizontal = () => {
    ScrollTrigger.saveStyles([barClassName, textClassName]);
    if (!textElement) return;
    textElement.innerHTML = '0 %';

    const timeline = gsap.timeline({
      defaults: {
        duration: 2
      },
      scrollTrigger: {
        trigger: barClassName,
        start: 'top 80%'
      }
    });

    timeline.from(barClassName, {
      scaleX: 0, // scaleX instead of width to avoid errors on resize
      transformOrigin: percentageValue === 20 ? 'right center' : 'left center',
      onUpdate: () => {
        const progress = (timeline.progress() * percentageValue).toFixed(0);
        textElement.innerHTML = `${prefix} ${progress} %`;
      }
    });

    return () => {
      timeline.kill();
    };
  };

  ScrollTrigger.matchMedia({
    // Phone Portrait and Phone Portrait XL
    '(max-width: 749px) and (orientation: portrait)': graphAnimationVertical,
    // Separated by orientations because otherwise we have problems during the
    // 50px breakpoint matched gap on Phone Landscape and Phone Portrait XL
    '(min-width: 700px) and (orientation: landscape)': graphAnimationHorizontal,
    '(min-width: 750px) and (orientation: portrait)': graphAnimationHorizontal
  });
};
