import React from 'react';

// External Components
import { Heading, Paragraph, Box, FlexWrapper } from '@thepuzzlers/pieces';

// Local Components
import { TealNoiseSection, GridSpacer, TextBlockWrapper } from 'components';

// Animations
import { statisticBarAnimation } from './animation';

// Helpers
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Productivity = ({ data: { headline, text_blocks, graph } }) => {
  return (
    // Markup
    <ContentWrapper>
      <Spacer position={0} />
      <GraphHeadline data={graph[0].headline} />
      <Spacer position={1} />
      <ManufacturingGraph
        title={convertApiHtmlText(graph[0].manufacturing_title.html)}
        percentage={convertApiHtmlText(graph[0].manufacturing_percentage.html)}
      />
      <VerticalLine />
      <ConstructionGraph
        title={convertApiHtmlText(graph[0].construction_title.html)}
        percentage={convertApiHtmlText(graph[0].construction_percentage.html)}
      />
      <Headline data={convertApiHtmlText(headline.html)} />
      <TextBlocks data={text_blocks} />
      <Spacer position={2} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection id="process-page__productivity">
    {children}
  </TealNoiseSection>
);

const Headline = ({ data }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      color: 'background',
      fontSize: ['2.8rem', '3.2rem', '3.4rem', '2.4rem', '2.8rem', '3.2rem'],
      fontFamily: 'primary.normal',
      gridColumn: [
        '1/13',
        '1/13',
        '4/span 18',
        '2/span 19',
        '7/span 15',
        '7/span 14'
      ],
      lineHeight: 1.35,
      mt: ['7.4rem', '17rem', '15.5rem', '10.9rem', '11.7rem', '12.8rem'],
      '& > span': {
        color: 'secondary',
        fontFamily: 'primary.italic'
      }
    }}
  />
);

const TextBlocks = ({ data }) => (
  <TextBlockWrapper
    className="productivity__text-blocks"
    data={data}
    lightText
    sx={{
      gap: ['5.6rem', '7.2rem', '5.2rem', '6.6rem', '4.9rem', '7rem'],
      gridColumn: [
        '1/13',
        '1/span 10',
        '4/span 15',
        '2/span 22',
        '7/span 15',
        '7/span 15'
      ],
      mt: ['5.6rem', '9.4rem', '6.9rem', '4.1rem', '6.4rem', '5.7rem']
    }}
  />
);

// Graph Elements

const GraphHeadline = ({ data }) => (
  <Heading
    as="h3"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      color: 'background',
      fontSize: ['2.8rem', '3.4rem', '2.8rem', '2.7rem', '2.8rem', '3.2rem'],
      fontFamily: 'primary.normal',
      gridColumn: [
        '1/13',
        '1/span 10',
        '8/25',
        '8/25',
        '7/span 13',
        '7/span 12'
      ],
      gridRow: 2,
      lineHeight: 1.35,
      width: ['100%', '96%', '100%', '100%', '100%', '100%']
    }}
  />
);

const ManufacturingGraph = ({ title, percentage }) => {
  React.useEffect(() => {
    return statisticBarAnimation(
      '.manufacturing-graph-bar',
      '.manufacturing-graph-text',
      300,
      '+'
    );
  }, []);

  return (
    <GraphElement
      className="productivity__manufacturing-graph"
      sx={{
        gridColumn: ['1/span 5', '2/span 4', '8/25', '8/25', '7/25', '7/25'],
        justifySelf: ['start', 'end', 'start', 'start', 'start', 'start'],
        width: [
          '100%',
          '100%',
          'max-content',
          'max-content',
          'max-content',
          'max-content'
        ]
      }}>
      <GraphTitle isWhite data={title} />
      <GraphBoxAndNumber
        sx={{
          justifyContent: 'space-between',
          width: [
            '100%',
            '100%',
            'max-content',
            'max-content',
            'max-content',
            'max-content'
          ]
        }}>
        <GraphText
          className="manufacturing-graph-text"
          isWhite
          isNumber
          data={percentage}
          sx={{
            ml: [0, 0, '0.9rem', '2rem', '0.8rem', '1.4rem']
          }}
        />
        <Box
          className="manufacturing-graph-bar"
          sx={{
            bg: 'background',
            borderRadius: '0.2rem',
            position: 'relative',
            height: [
              '40.3rem',
              '40.3rem',
              '4.5rem',
              '3.2rem',
              '4.5rem',
              '4.5rem'
            ],
            width: [
              '4.5rem',
              '4.9rem',
              '41.5rem',
              '39.8rem',
              '62.2rem',
              '79.4rem'
            ]
          }}
        />
      </GraphBoxAndNumber>
    </GraphElement>
  );
};

const ConstructionGraph = ({ title, percentage }) => {
  React.useEffect(() => {
    return statisticBarAnimation(
      '.construction-graph-bar',
      '.construction-graph-text',
      20,
      '-'
    );
  }, []);

  return (
    <GraphElement
      className="productivity__construction-graph"
      sx={{
        alignSelf: 'end',
        gridColumn: [
          '8/13',
          '8/span 4',
          '1/span 5',
          '1/span 5',
          '1/span 4',
          '3/span 3'
        ],
        justifySelf: ['start', 'start', 'end', 'end', 'end', 'end']
      }}>
      <GraphTitle data={title} />
      <GraphBoxAndNumber
        sx={{
          ml: [null, null, 'auto', 'auto', 'auto', 'auto']
        }}>
        <Box
          className="construction-graph-bar"
          sx={{
            bg: 'secondary',
            borderRadius: '0.2rem',
            height: [
              '3.8rem',
              '5.7rem',
              '4.5rem',
              '3.2rem',
              '4.5rem',
              '4.5rem'
            ],
            width: ['4.5rem', '4.9rem', '4.5rem', '3.2rem', '3.8rem', '3.8rem'],
            // ? alternative to the flex gap
            ml: [null, null, '1.6rem', '1.6rem', '0.8rem', '1.6rem']
          }}
        />
        <GraphText
          className="construction-graph-text"
          isNumber
          data={percentage}
          sx={{
            // ? alternative to the flex gap
            ml: ['1.8rem', '2rem', 0, 0, 0]
          }}
        />
      </GraphBoxAndNumber>
    </GraphElement>
  );
};

const VerticalLine = () => (
  <Box
    className="productivity__graph-divider-line"
    sx={{
      alignSelf: 'end',
      bg: 'background',
      gridColumn: [
        '6/span 2',
        '6/span 2',
        '6/span 2',
        '6/span 2',
        '5/span 2',
        '6/span 1'
      ],
      gridRow: [4, 4, '2/span 3', '2/span 3', '2/span 3', '2/span 3'],
      justifySelf: 'center',
      height: ['40.3rem', '40.3rem', '100%', '100%', '100%', '100%'],
      transform: [
        'unset',
        'unset',
        'scaleY(1.2)',
        'scaleY(1.1)',
        'scaleY(1.1)',
        'scaleY(1.1)'
      ],
      width: '0.1rem'
    }}
  />
);

// Reusable Components

const GraphElement = ({ children, sx, ...props }) => (
  <Box
    sx={{
      gridRow: 4,
      width: [
        'min-content',
        'min-content',
        'max-content',
        'max-content',
        'max-content',
        'max-content'
      ],
      ...sx
    }}
    {...props}>
    {children}
  </Box>
);

const GraphBoxAndNumber = ({ children, sx }) => (
  <FlexWrapper
    className="graph-box-and-number"
    sx={{
      alignItems: 'center',
      flexDirection: [
        'row',
        'row',
        'row-reverse',
        'row-reverse',
        'row-reverse'
      ],
      mt: ['2.8rem', '2.8rem', '1.6rem', '1.9rem', '2.6rem', '2.4rem'],
      width: 'max-content',
      ...sx
    }}>
    {children}
  </FlexWrapper>
);

const GraphTitle = ({ data, ...props }) => {
  return (
    <GraphText
      data={data}
      sx={{
        '& > br': {
          display: ['block', 'block', 'block', 'block', 'block', 'none'] // br for english version
        }
      }}
      {...props}
    />
  );
};

const GraphText = ({
  data,
  isWhite = false,
  isNumber = false,
  className,
  sx
}) => (
  <Paragraph
    className={className}
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      color: isWhite ? 'background' : 'secondary',
      fontFamily: isNumber ? 'primary.italic' : 'primary.bold',
      fontSize: ['1.8rem', '2.2rem', '2rem', '1.8rem', '1.8rem', '2rem'],
      lineHeight: 1.15,
      ...sx
    }}
  />
);

// Spacer

// First and last spacer are used inside the section to keep same pattern of bg noise
const Spacer = ({ position }) => {
  const styles = [
    {
      height: ['11.5rem', '16.3rem', '18.4rem', '12.1rem', '18.8rem', '18.5rem']
    },
    {
      gridRow: 3,
      height: ['3.8rem', '6.6rem', '3.5rem', '3.7rem', '3.3rem', '3.1rem']
    },
    {
      // without image underneath
      height: ['16rem', '18.2rem', '15.3rem', '9.8rem', '18.9rem', '24.6rem']
      //  with image underneath
      // height: ['28.7rem', '18.2rem', '15.3rem', '9.8rem', '18.9rem', '24.6rem']
    }
  ];

  return <GridSpacer sx={{ ...styles[position] }} />;
};
