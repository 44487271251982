import React from 'react';

// External Components
import { Box, Section } from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  ImageContainer,
  UnderlinedHeading,
  MarkedHeading
} from 'components';

// Animations
import { revealDescription, scaleImage } from './animations';

// Helpers
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Header = ({
  data: { description },
  staticData: { headline, images }
}) => {
  return (
    // Markup
    <ContentWrapper>
      <Spacer position={0} />
      <Headline data={headline} />
      <Images data={images} />
      <Description data={convertApiHtmlText(description.html)} />
      <Spacer position={1} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    id="process-page__header"
    // Animation values
    initial="initial"
    animate="animate">
    {children}
  </Section>
);

const Headline = ({ data }) => (
  <UnderlinedHeading
    as="h1"
    data={data}
    sx={{
      fontSize: ['3.2rem', '4rem', '4.4rem', '3.2rem', '4.4rem', '4.8rem'],
      gridColumn: [
        '1/13',
        '1/span 10',
        '1/25',
        '1/span 20',
        '1/span 19',
        '1/span 17'
      ],
      gridRow: [2, 2, 3, 3, 3, 3],
      lineHeight: 1.35,
      mt: [0, 0, '4.8rem', '4rem', '6.4rem', '4.7rem'],
      width: ['94%', '100%', '100%', '100%', '100%', '100%'],
      // Handle line breaks across breakpoints
      '& > br:first-of-type': {
        display: ['none', 'none', 'block', 'block', 'block', 'block'],
        ml: [null, null, null, null, '12.6rem', '12.6rem']
      },
      '& > br:last-of-type': {
        display: ['none', 'none', 'none', 'none', 'none', 'none']
      },
      '& > span:first-of-type': {
        fontFamily: 'primary.normal',
        ml: [0, 0, '9rem', '11rem', '13rem', '16rem'],
        '&::before': {
          display: 'none'
        }
      },
      '& > .with-underline-1': {
        ml: [0, 0, 0, 0, 0, 0],
        '&::before': {
          animation: '0.4s linear 0.2s revealUnderline',
          animationFillMode: 'forwards',
          width: 0
        }
      },
      '& > .with-underline-2::before': {
        animation: '1s ease-out 0.6s revealUnderline',
        animationFillMode: 'forwards',
        width: 0
      }
    }}
  />
);

const Images = ({ data }) => (
  <Box
    className="process-page__header__images"
    sx={{
      display: 'grid',
      gap: ['1.2rem', '1.6rem', '1.2rem', '0.8rem', '1.2rem', '1.6rem'],
      gridColumn: [
        '3/13',
        '5/13',
        '1/25',
        '1/span 20',
        '1/span 15',
        '1/span 15'
      ],
      gridRow: [3, 3, 2, 2, 2, 2],
      gridTemplateColumns: [
        '1fr',
        '1fr',
        '1fr 1fr',
        '1fr 1fr',
        '1fr 1fr',
        '1fr 1fr'
      ],
      mt: ['6.3rem', '5.2rem', 0, 0, 0, 0]
    }}>
    {data.map((img) => (
      <Box
        key={img.alt}
        sx={{
          aspectRatio: [
            '1.16/1',
            '1.16/1',
            '1.27/1',
            '1.16/1',
            '1.16/1',
            '1.16/1'
          ],
          borderRadius: 'card',
          overflow: 'hidden'
        }}>
        <ImageContainer
          {...img}
          // Animation value
          variants={scaleImage}
          sx={{ height: '100%', width: '100%' }}
        />
      </Box>
    ))}
  </Box>
);

const Description = ({ data }) => (
  <MarkedHeading
    as="p"
    data={data}
    // Animation value
    variants={revealDescription}
    sx={{
      alignSelf: 'start',
      fontFamily: 'primary.normal',
      fontSize: ['1.6rem', '1.7rem', '2rem', '1.5rem', '1.5rem', '1.6rem'],
      fontFeatureSettings: "'pnum' on, 'onum' on",
      gridColumn: [
        '1/13',
        '1/span 9',
        '2/span 16',
        '2/span 12',
        '17/span 7',
        '17/span 7'
      ],
      gridRow: [4, 4, 4, 4, 2, 2],
      lineHeight: 1.5,
      mt: ['5.6rem', '5.2rem', '7.2rem', '4rem', 0, 0]
    }}
  />
);

// Spacer

const Spacer = ({ position }) => {
  const heights = [
    ['9.7rem', '14.8rem', '20.7rem', '12.2rem', '15.6rem', '14.6rem'],
    ['12.7rem', '18rem', '14.2rem', '7.5rem', '9.4rem', '11.7rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
