import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { MarkedHeading, TextBlockWrapper, GridSpacer } from 'components';

// Helpers
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Standards = ({ data: { headline, text_blocks } }) => {
  return (
    // Markup
    <Section id="process-page__standards">
      <Spacer position={0} />
      <Headline data={convertApiHtmlText(headline.html)} />
      <TextBlocks data={text_blocks} />
      <Spacer position={1} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={data}
    sx={{
      fontSize: ['2.4rem', '2.6rem', '3rem', '2.4rem', '2.8rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 22',
        '2/span 22',
        '3/span 17',
        '4/span 15'
      ],
      lineHeight: 1.25,
      pr: [0, 0, 0, 0, '1rem', 0]
    }}
  />
);

const TextBlocks = ({ data }) => (
  <TextBlockWrapper
    className="standards__text-blocks"
    data={data}
    sx={{
      gap: ['3.2rem', '4.8rem', '6.4rem', '6.7rem', '5rem', '7rem'],
      gridColumn: [
        '1/13',
        '1/span 9',
        '2/span 17',
        '2/span 22',
        '3/span 17',
        '4/span 15'
      ],
      mt: ['4.8rem', '7.2rem', '7rem', '4rem', '5.8rem', '7.2rem']
    }}
    textSx={{
      fontSize: ['1.6rem', '1.6rem', '2rem', '1.5rem', '1.5rem', '1.6rem']
    }}
  />
);

// Spacer

const Spacer = ({ position }) => {
  const heights = [
    ['15.2rem', '21.2rem', '16.9rem', '10.8rem', '20.8rem', '23.5rem'],
    ['27rem', '22rem', '17rem', '15rem', '26rem', '29rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
