const transitionDefault = {
  type: 'tween',
  ease: 'easeOut'
};

export const revealDescription = {
  initial: {
    opacity: 0,
    y: 60
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ...transitionDefault,
      duration: 0.8,
      delay: 1.2
    }
  }
};

export const scaleImage = {
  initial: {
    scale: 1.1
  },
  animate: {
    scale: 1,
    transition: {
      ...transitionDefault,
      duration: 1.6
    }
  }
};
