import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useProcessPageData = () => {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: processPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
          images {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
            alt
          }
        }

        steps {
          steps {
            number {
              src {
                publicURL
              }
              alt
            }
          }
        }
      }
      de: deProcessPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
          images {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        steps {
          steps {
            number {
              src {
                publicURL
              }
              alt
            }
          }
        }
      }
    }
  `);

  return data[locale];
};
