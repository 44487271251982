import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  useProcessPageData,
  Header,
  Productivity,
  Steps,
  Standards
} from 'sections/processPage';

// Cms data
import { useKisProcessPageData } from 'graphqlHooks';

const Process = () => {
  const { seo, header, steps } = useProcessPageData();

  const {
    header: cmsHeader,
    productivity: cmsProductivity,
    steps: cmsSteps,
    standards: cmsStandards
  } = useKisProcessPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={cmsHeader} staticData={header} />
      <Productivity data={cmsProductivity} />
      <Steps data={cmsSteps} staticData={steps} />
      <Standards data={cmsStandards} />
    </>
  );
};

export default injectIntl(Process);
